html,
body,
#root,
.App {
  height: 100%;
}

body {
  background: #f4f5f7;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 0;
  appearance: none;
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0%);
  }
}

.tooltip .tooltip-text {
  visibility: hidden;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.bottom-full {
  bottom: 100%;
}
.top-full {
  top: 100%;
}
